import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    fallbackLng: {
      default: ['es']
    },
    debug: true,
    ns: ["common", "menu", "home", "pages-titles", "contact", "not-found", "cookies", "pricing", "history"],
    defaultNS: ["common"],

    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;