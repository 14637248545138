import { createBrowserRouter } from "react-router-dom";
import { Suspense } from 'react';
import Home from '../pages/home/Home';
import NotFound from '../pages/not-found/NotFound';
import History from '../pages/history/History';
import AppLayout from '../layouts/AppLayout';
import Contact from '../pages/contact/Contact';
import HomeLayout from '../layouts/HomeLayout';
import Pricing from '../pages/pricing/Pricing';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Suspense fallback={<div>Cargando...</div>}><HomeLayout /></Suspense>,
    children: [
      {
        index: true,
        element: <Home />
      }
    ]
  },
  {
    element: <Suspense fallback={<div>Cargando...</div>}><AppLayout /></Suspense>,
    children: [
      {
        path: '/history',
        element: <History />
      },
      {
        path: '/pricing',
        element: <Pricing />
      },
      {
        path: '/contact',
        element: <Contact />
      },
      {
        path: '*',
        element: <NotFound />
      },
    ]
  }
]);

export default router;
