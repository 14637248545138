import { Box } from "@mui/material";
import NavigationBar from "../core/NavigationBar";
import TopBar from "./TopBar";

export default function PagesHeader() {

  return (
    <Box className="home-header" sx={{backgroundImage: "url(img/main-rev-img-1.jpg)", backgroundSize: "cover", backgroundPosition: "center", display: "flex", flexDirection: "column", color: "#fff"}}>
      <TopBar />
      <NavigationBar />
    </Box>
  );
}