import { Outlet } from "react-router-dom";
import Footer from "../components/core/Footer";
import PagesHeader from "src/components/shared/PagesHeader";

export default function AppLayout () {
    return (
        <div>
            <PagesHeader />
            <Outlet />
            <Footer />
        </div>
    )
}