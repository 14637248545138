import { Box, Typography } from "@mui/material";

export default function FullWidth ({image, title, text}){
    return(
        <>
            <Box sx={{borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0,0,0,0.05) 0px 3px 5px 1px", position: "relative", width: "100%", height: "335px" }}>
                <img src={image} alt={title} width="100%" height="auto" style={{display: "block"}} />
            </Box>
            <Typography variant="h5" color="primary">{title}</Typography>
            <Typography variant="body1" sx={{textAlign: "justify"}}>{text}</Typography>
        </>
    )
}