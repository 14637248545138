import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function HomeText () {
    const { t } = useTranslation(['home']);
    return (
        <Box sx={{flexGrow: 1, display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", alignSelf: "center"}}>
            <Typography variant="h4" sx={{textTransform: "uppercase", textAlign: "center"}}>{t('title')}</Typography>
            <Divider role="presentation" className="subtitle" sx={{width: "80%"}}>{t('subtitle')}</Divider>
        </Box>
    )
}