import { useEffect } from 'react';

export default function PageTitle({ children }) {
  useEffect(() => {
    document.title = children;
    return () => {
      document.title = 'Château';
    };
  }, [children]);

  return null;
}