import './App.css';
import { RouterProvider } from "react-router-dom";
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import './services/i18n';
import theme from './services/theme';
import router from './services/router';
import CookiesPolicyDialog from './components/core/CookiesPolicyDialog';

function App() {
  return (
    <CssVarsProvider theme={theme}>
      <div className="App">
        <CookiesPolicyDialog />
        <RouterProvider router={router} />
      </div>
    </CssVarsProvider>
  );
}

export default App;