import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function TextComponent ({ title, subtitle, paragraph1, paragraph2 })  {
  return (
    <Grid container spacing={3} sx={{p: 5}}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" color="secondary.main">{title}</Typography>
        <Typography variant="subtitle1" color="primary.main"><i>{subtitle}</i></Typography>
      </Grid>
      <Grid item xs={12} sm={6} sx={{textAlign: "justify"}}>
        <Typography variant="body1" sx={{mb: 2}}>{paragraph1}</Typography>
        <Typography variant="body1">{paragraph2}</Typography>
      </Grid>
    </Grid>
  );
};