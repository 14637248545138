import { Box } from "@mui/material";

export default function TabPanel (props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
            <Box sx={{borderRadius: "10px", overflow: "hidden", width: "100%", height: "335px", boxShadow: "rgba(0,0,0,0.05) 0px 3px 5px 1px"}}>
                <img src={props.image} width="100%" height="auto" alt={props.label} style={{ display: "block" }} />
            </Box>
            {children}
          </Box>
        )}
      </div>
    );
  };