// LanguageSelector.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material';

export default function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  function returnLangDetected() {
    return ['es', 'en'].includes(i18n.language) ? i18n.language : 'es'
  }

  return (
    <div>
      <Select
        id="language-select"
        onChange={(e) => changeLanguage(e.target.value)}
        value={returnLangDetected()}
        size="small"
      >
        <MenuItem value="es">
            <Box sx={{display: "flex", alignItems: "center", gap: "10px"}}>
                <img src="img/spain.png" width={20} alt="Español" />
                <span>Español</span>
            </Box>
        </MenuItem>
        <MenuItem value="en">
            <Box sx={{display: "flex", alignItems: "center", gap: "10px"}}>
                <img src="img/united-kingdom.png" width={20} alt="English" />
                <span>English</span>
            </Box>
        </MenuItem>
      </Select>
    </div>
  );
}
