import { Box, Grid, List, ListItem, ListItemText, Typography, useColorScheme, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import LanguageSelector from "./LanguageSelector";
import ColorModeSwitcher from "./ColorModeSwitcher";
import { useTranslation } from "react-i18next";
import { AccessTime, KeyboardArrowRightRounded, LocationOn, Mail, Phone } from "@mui/icons-material";

export default function Footer() {
    const { t } = useTranslation();
    const colorMode = useColorScheme();
    return (
        <Grid container spacing={2} sx={{ mt: "40px", p: "40px", borderTop: 1, borderColor: colorMode.mode === "dark" ? "primary.main" : "grey.300", backgroundColor: colorMode.mode === "dark" ? "#0c0208" : "" }}>
            <Grid item xs={12} md={6}>
                <Logo variant={colorMode.mode === "light" ? "dark" : "light"} height="50px" />
                <List dense>
                    <ListItem sx={{ pl: "0" }}>
                        <LocationOn sx={{ mr: "10px" }} />
                        <ListItemText primary={t("location", { ns: 'common' })} />
                    </ListItem>
                    <ListItem sx={{ pl: "0" }}>
                        <AccessTime sx={{ mr: "10px" }} />
                        <ListItemText primary={t("schedule", { ns: 'common' })} />
                    </ListItem>
                    <ListItem sx={{ pl: "0" }}>
                        <Phone sx={{ mr: "10px" }} />
                            <MuiLink href="tel:+34612345678" color="primary">
                                <ListItemText primary="+34 612 34 56 78" />
                            </MuiLink>
                    </ListItem>
                    <ListItem sx={{ pl: "0" }}>
                        <Mail sx={{ mr: "10px" }} />
                        <MuiLink href="mailto:info@celler.com" color="primary">
                            <ListItemText primary="info@celler.com" />
                        </MuiLink>
                    </ListItem>
                </List>
                <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                    <LanguageSelector />
                    <ColorModeSwitcher />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h6">{t("quick-links", { ns: 'common' })}</Typography>
                <List>
                    <ListItem>
                        <Link to="/">
                            <Typography color="primary" sx={{ display: "flex", alignItems: "center" }}>
                                <KeyboardArrowRightRounded />
                                {t("home", { ns: 'menu' })}
                            </Typography>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to="/history">
                            <Typography color="primary" sx={{ display: "flex", alignItems: "center" }}>
                                <KeyboardArrowRightRounded />
                                {t("history", { ns: 'menu' })}
                            </Typography>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to="/pricing">
                            <Typography color="primary" sx={{ display: "flex", alignItems: "center" }}>
                                <KeyboardArrowRightRounded />
                                {t("prices", { ns: 'menu' })}
                            </Typography>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to="/contact">
                            <Typography color="primary" sx={{ display: "flex", alignItems: "center" }}>
                                <KeyboardArrowRightRounded />
                                {t("contact", { ns: 'menu' })}
                            </Typography>
                        </Link>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    )
}