import LocationOn from '@mui/icons-material/LocationOn';
import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function TopBar() {
    const { t } = useTranslation();
    return (
        <Box className="home-top-bar" sx={{display: { xs: 'none', sm: 'flex' }, justifyContent: "space-between", alignItems: "center", padding: "5px 20px", borderBottom: "1px solid #fff"}}>
            <Typography variant="body2">
            {t('top-bar.slogan', { ns: 'common' })}
            </Typography>
            <Link variant="body2" rel="noreferrer" href="https://maps.app.goo.gl/gBGW8eKkJDYgKaeJ6" target="_blank" underline="none" sx={{display: "flex", alignItems: "center", color: "#fff"}}>
                <LocationOn fontSize="small" />{t('location', { ns: 'common' })}
            </Link>
        </Box>
    )
}