import React, { useState, useEffect } from 'react';
import { Box, Chip, Container, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';


export default function WeatherComponent() {
  const { t } = useTranslation();
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://www.el-tiempo.net/api/json/v2/provincias/17/municipios/17078`
        );
        if (!response.ok) {
          throw new Error('Error al obtener los datos del tiempo');
        }
        const data = await response.json();
        setWeatherData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Container sx={{mb: "30px"}}>
      {loading ? (
        <Typography variant="h6">Cargando datos...</Typography>
      ) : (
        <div>
          <Typography color="secondary.main" variant="h4" sx={{ textAlign: 'center', mb: '20px' }}>
            {t("weather-forecast.title", { ns: 'home' })}
          </Typography>
          <Grid container rowSpacing={2} columnSpacing={2} sx={{px: "30px"}}>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={0} sx={{ boxShadow: 'rgba(0,0,0,0.12) 0px 3px 5px 1px', p: '10px', textAlign: 'center' }}>
                <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: '700' }}>
                  {t('weather-forecast.today', { ns: 'home' })}
                </Typography>
                <Typography variant="body1">
                  {new Date(weatherData.fecha).getDate() +
                    ' ' +
                    t(`weather-forecast.months.${new Date(weatherData.fecha).getMonth()}`, { ns: 'home' })}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', mt: '10px' }}>
                  <Chip icon={<ArrowDropUpRounded />} label={weatherData.temperaturas.max + ' °C'} variant="outlined" color="error" sx={{ fontWeight: '700' }} />
                  <Chip icon={<ArrowDropDownRounded />} label={weatherData.temperaturas.min + ' °C'} variant="outlined" color="info" sx={{ fontWeight: '700' }} />
                </Box>
              </Paper>
            </Grid>
            {weatherData.proximos_dias.slice(0, 5).map((dia) => {
               const fechaObj = new Date(dia['@attributes'].fecha);
               const diaDeLaSemana = t(`weather-forecast.weekdays.${fechaObj.getDay()}`, { ns: 'home' });
               const diaDelMes = fechaObj.getDate();
               const mes = t(`weather-forecast.months.${fechaObj.getMonth()}`, { ns: 'home' });
              return (
                <Grid item xs={12} sm={6} md={4} key={dia['@attributes'].fecha}>
                    <Paper elevation={0} sx={{ boxShadow: 'rgba(0,0,0,0.12) 0px 3px 5px 1px', p: "10px", textAlign: "center" }}>
                    <Typography variant="h6" sx={{textTransform: "uppercase", fontWeight: '700'}}>{diaDeLaSemana}</Typography>
                    <Typography variant="body1">{`${diaDelMes} ${mes}`}</Typography>
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", mt: "10px"}}>
                      <Chip icon={<ArrowDropUpRounded />} label={dia.temperatura.maxima + " °C"} variant="outlined" color="error" sx={{ fontWeight: '700'}} />
                      <Chip icon={<ArrowDropDownRounded />} label={dia.temperatura.minima + " °C"} variant="outlined" color="info" sx={{ fontWeight: '700'}} />
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
    </Container>
  );
}

