import { HomeRounded } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "src/components/core/PageTitle";

export default function NotFound() {
    const { t } = useTranslation(["not-found", "pages-titles"]);
    return (
        <>
            <PageTitle>{t("not-found", { ns: "pages-titles" })}</PageTitle>
            <Grid container spacing={2} sx={{ p: "50px", pb: "0px" }}>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <Typography variant="h4" color="secondary.main" sx={{ mb: "20px" }}>{t("error-title")}</Typography>
                    <Typography variant="h6" color="primary.main" sx={{ mb: "30px" }}><i>{t("error-subtitle")}</i></Typography>
                    <Link to="/">
                        <Button size="large" variant="contained" sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <HomeRounded />
                            {t("go-back-home")}
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Box sx={{ width: { xs: "100%", md: "80%" } }}>
                        <img src="img/404.png" alt="Error 404" width="100%" />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}