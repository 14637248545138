export default function Logo({ variant, height }) {
    const src = variant === 'light' ? 'img/logo-light-new.png' : 'img/logo-dark-new.png';
  
    return (
      <img
        src={src}
        height={height}
        alt="Logo"
      />
    );
  }