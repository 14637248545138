import { DarkMode, LightMode } from "@mui/icons-material";
import { useColorScheme, Button, ButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ColorModeSwitcher() {
  const { t } = useTranslation();
  const { mode, setMode } = useColorScheme();

  return (
    <ButtonGroup>
      <Button variant={mode === "light" ? "contained" : "outlined"} onClick={() => { setMode('light') }}>
        <LightMode sx={{mr: "10px"}} />
        {t("light-mode")}
      </Button>
      <Button variant={mode === "dark" ? "contained" : "outlined"} onClick={() => { setMode('dark') }}>
        <DarkMode sx={{mr: "10px"}} />
        {t("dark-mode")}
      </Button>
    </ButtonGroup>
  );
};