import { useState } from 'react';
import { LocationOn } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Grid, TextField, Typography, useColorScheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

export default function ContactForm() {
    const theme = useTheme();
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: {
            asunto: '',
            nombre: '',
            email: '',
            mensaje: '',
        },
    });
    const { t } = useTranslation(['contact']);
    const colorMode = useColorScheme();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    async function onSubmit(data) {
        try {
            setLoading(true);

            // Send form data to the server
            const response = await fetch('https://formsubmit.co/f40da3d5d7c8a42d91fbc430de0b1fcb', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setSuccess(true);
                reset();
            } else {
                setError(t('contact-form.error'));
            }
        } catch (error) {
            setError(t('contact-form.error'));
        } finally {
            setLoading(false);
        }
    }

    return (
        <Grid container spacing={2} sx={{ p: { xs: "20px 20px 0px 20px", sm: "50px 50px 10px 50px" } }}>
            <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Box sx={{ flexGrow: "1", minHeight: { xs: "300px" }, boxShadow: 'rgba(0,0,0,0.05) 0px 3px 5px 1px', border: "1px solid", borderColor: colorMode.mode === "dark" ? "primary.dark" : "grey.300", borderRadius: "8px", p: "10px" }}>
                    <iframe
                        title="My Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.9108929762237!2d0.2901507125792786!3d44.76223197095053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12aabf5b6f741fdd%3A0xe017784f58262991!2sLe%20Bernard%2C%2033220%20Margueron%2C%20Francia!5e0!3m2!1ses!2ses!4v1702034439487!5m2!1ses!2ses"
                        width="100%"
                        height="100%"
                        style={{ border: 0, borderRadius: "8px" }}
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </Box>
                <Box sx={{
                    boxShadow: 'rgba(0,0,0,0.05) 0px 3px 5px 1px', p: "20px", borderRadius: "8px", display: "flex", gap: "10px", background: theme.palette.background.paper,
                    border: "1px solid", borderColor: colorMode.mode === "dark" ? "primary.dark" : "grey.300"
                }}>
                    <LocationOn />
                    {t("location", { ns: 'common' })}
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", boxShadow: 'rgba(0,0,0,0.05) 0px 3px 5px 1px', p: "20px", borderRadius: "8px", background: theme.palette.background.paper, border: "1px solid", borderColor: colorMode.mode === "dark" ? "primary.dark" : "grey.300" }}>
                        <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>{t('contact-form.title')}</Typography>
                        {success && (
                            <Alert severity="success" sx={{ mt: 2 }}>
                                {t('contact-form.success')}
                            </Alert>
                        )}
                        {error && (
                            <Alert severity="error" sx={{ mt: 2 }}>
                                {error}
                            </Alert>
                        )}
                        <Controller
                            name="asunto"
                            control={control}
                            rules={{
                                required: t('contact-form.subject.required'),
                                maxLength: {
                                    value: 50,
                                    message: t('contact-form.subject.max'),
                                }
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label={t('contact-form.subject.title')}
                                    variant="outlined"
                                    error={!!errors.asunto}
                                    helperText={errors?.asunto?.message.toString()}
                                />
                            }
                        />
                        <Controller
                            name="nombre"
                            control={control}
                            rules={{
                                required: t('contact-form.name.required'),
                                pattern: {
                                    value: /^[A-Za-z ]+$/,
                                    message: t('contact-form.name.pattern'),
                                },
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label={t('contact-form.name.title')}
                                    variant="outlined"
                                    error={!!errors.nombre}
                                    helperText={errors?.nombre?.message.toString()}
                                />
                            }
                        />
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: t('contact-form.email.required'),
                                pattern: {
                                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                    message: t('contact-form.email.pattern'),
                                },
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label={t('contact-form.email.title')}
                                    variant="outlined"
                                    error={!!errors.email}
                                    helperText={errors?.email?.message.toString()}
                                />
                            }
                        />
                        <Controller
                            name="mensaje"
                            control={control}
                            rules={{
                                required: t('contact-form.message.required')
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label={t('contact-form.message.title')}
                                    variant="outlined"
                                    multiline
                                    rows={6}
                                    error={!!errors.mensaje}
                                    helperText={errors?.mensaje?.message.toString()}
                                />
                            }
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ width: "max-content", alignSelf: "center" }}
                            disabled={loading || success}
                            startIcon={loading && <CircularProgress size={20} color="inherit" />}
                        >
                            {loading ? t('contact-form.submit-btn.sending') : t('contact-form.submit-btn.send')}
                        </Button>
                    </Box>
                </form>
            </Grid>
        </Grid>
    );
}