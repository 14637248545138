import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "src/components/core/PageTitle";
import TabElement from "src/components/pricing/TabElement";
import TabPanel from "src/components/pricing/TabPanel";

export default function Pricing() {
    const { t } = useTranslation(['pricing', 'pages-titles']);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <PageTitle>{t('pricing', { ns: "pages-titles" })}</PageTitle>
            <Box sx={{ width: "100%", maxWidth: "1200px", mx: "auto", px: "40px" }}>
                <Typography variant="h4" color="primary" sx={{ textAlign: "center", mt: "30px", mb: "20px" }}>{t("title")}</Typography>
                <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile sx={{mb: "20px", width: "max-content", maxWidth: "100%", mx: "auto"}}>
                    <Tab label={t("wine-tasting.label")} />
                    <Tab label={t("winery-tours.label")} />
                    <Tab label={t("special-events.label")} />
                    <Tab label={t("monthly-subscription.label")} />
                </Tabs>
                <TabPanel value={value} index={0} label={t("wine-tasting.label")} image="img/pricing-wine-tasting.jpg">
                    <TabElement
                        label={t("wine-tasting.basic.label")}
                        data={t("wine-tasting.basic.data")}
                        price={t("wine-tasting.basic.price")}
                        duration={t("wine-tasting.basic.duration")}
                    />
                    <TabElement
                        label={t("wine-tasting.premium.label")}
                        data={t("wine-tasting.premium.data")}
                        price={t("wine-tasting.premium.price")}
                        duration={t("wine-tasting.premium.duration")}
                    />
                    <TabElement
                        label={t("wine-tasting.custom.label")}
                        data={t("wine-tasting.custom.data")}
                        price={t("wine-tasting.custom.price")}
                        duration={t("wine-tasting.custom.duration")}
                    />
                </TabPanel>
                <TabPanel value={value} index={1} label={t("winery-tours.label")} image="img/pricing-winery-tours.jpg">
                    <TabElement
                        label={t("winery-tours.standard.label")}
                        data={t("winery-tours.standard.data")}
                        price={t("winery-tours.standard.price")}
                        duration={t("winery-tours.standard.duration")}
                    />
                    <TabElement
                        label={t("winery-tours.vip.label")}
                        data={t("winery-tours.vip.data")}
                        price={t("winery-tours.vip.price")}
                        duration={t("winery-tours.vip.duration")}
                    />
                </TabPanel>

                <TabPanel value={value} index={2} label={t("special-events.label")} image="img/pricing-special-events.jpg">
                    <TabElement
                        label={t("special-events.pairing-night.label")}
                        data={t("special-events.pairing-night.data")}
                        price={t("special-events.pairing-night.price")}
                        duration={t("special-events.pairing-night.duration")}
                    />
                    <TabElement
                        label={t("special-events.private-celebration.label")}
                        data={t("special-events.private-celebration.data")}
                        price={t("special-events.private-celebration.price")}
                        details={t("special-events.private-celebration.details")}
                    />
                </TabPanel>

                <TabPanel value={value} index={3} label={t("monthly-subscription.label")} image="img/pricing-monthly-suscription.jpg">
                    <TabElement
                        label={t("monthly-subscription.wine-club.label")}
                        data={t("monthly-subscription.wine-club.data")}
                        price={t("monthly-subscription.wine-club.price")}
                        delivery={t("monthly-subscription.wine-club.delivery")}
                    />
                </TabPanel>
            </Box>
        </>
    )
}