import React from 'react';
import PropTypes from 'prop-types';
import { ImageList, ImageListItem, useMediaQuery } from '@mui/material';

const ImageGrid = ({ imageUrls }) => {
  const matches = useMediaQuery('(min-width:800px)');
  return (
    <ImageList cols={matches ? 6 : 2} sx={{m: "50px"}} gap={8}>
      {imageUrls.map((url, index) => (
        <ImageListItem key={index} sx={{borderRadius: "8px", overflow: "hidden"}}>
          <img
            src={url}
            alt=""
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

ImageGrid.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ImageGrid;
