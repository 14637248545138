import { Box, Grid, Typography } from "@mui/material";

export default function TwoColumns ({firstImage, firstTitle, firstText, lastImage, lastTitle, lastText}) {
    return(
        <>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} sx={{display: "flex", flexDirection: "column", gap: "20px"}}>
                <Box sx={{borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0,0,0,0.05) 0px 3px 5px 1px", width: "100%", height: "300px"}}>
                    <img src={firstImage} width="100%" height="auto" alt={firstTitle} style={{ display: "block" }} />
                </Box>
                <Typography variant="h5" color="primary">{firstTitle}</Typography>
                <Typography variant="body1" sx={{textAlign: "justify"}}>{firstText}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: "flex", flexDirection: "column", gap: "20px"}}>
                <Box sx={{borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0,0,0,0.05) 0px 3px 5px 1px", width: "100%", height: "300px"}}>
                    <img src={lastImage} width="100%" height="auto" alt={lastTitle} style={{ display: "block" }} />
                </Box>
                <Typography variant="h5" color="primary">{lastTitle}</Typography>
                <Typography variant="body1" sx={{textAlign: "justify"}}>{lastText}</Typography>
            </Grid>
        </Grid>
        </>
    )
}