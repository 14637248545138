import { experimental_extendTheme } from '@mui/material';


const theme = experimental_extendTheme({
    colorSchemes: {
        light: {
          palette: {
            primary: {
                main: '#710d3f',
            },
            secondary: {
                main: '#a12362',
            }
          },
        },
        dark: {
          palette: {
            primary: {
                main: '#c66294',
            },
            secondary: {
                main: '#b07492',
            },
            background: {
                default: '#1a0512',
                paper: '#310b1f',
            },
            text: {
                primary: '#d6c0cb',
            }
          },
        },
      },
    typography: {
        fontFamily: 'Montserrat',
        h3: {
          fontWeight: '700',
        },
        h4: {
          fontWeight: '700',
          textTransform: 'uppercase'
        },
    }
});

export default theme;
