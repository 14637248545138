import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageTitle from "src/components/core/PageTitle";
import FullWidth from "src/components/history/FullWidth";
import TwoColumns from "src/components/history/TwoColumns";

export default function History (){
    const { t } = useTranslation(['history', 'pages-titles']);
    return (
        <>
            <PageTitle>{t('history', { ns: "pages-titles" })}</PageTitle>
            <Box sx={{ width: "100%", maxWidth: "1200px", mx: "auto", px: "40px", display: "flex", gap: "20px", flexWrap: "wrap" }}>
                <Typography variant="h4" color="primary" sx={{ flexGrow: "1", textAlign: "center", mt: "30px", mb: "20px" }}>{t("title")}</Typography>
                <FullWidth image="img/history-welcome.jpg" title={t("welcome.title")} text={t("welcome.text")} />
                <TwoColumns
                    firstImage="img/history-root.jpg"
                    firstTitle={t("root.title")}
                    firstText={t("root.text")}
                    lastImage="img/history-varieties.jpg"
                    lastTitle={t("varieties.title")}
                    lastText={t("varieties.text")}
                />
                <TwoColumns
                    firstImage="img/history-tasting.jpg"
                    firstTitle={t("tasting.title")}
                    firstText={t("tasting.text")}
                    lastImage="img/history-commitment.jpg"
                    lastTitle={t("commitment.title")}
                    lastText={t("commitment.text")}
                />
            </Box>
        </>
    )
}