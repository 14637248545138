import { useTranslation } from "react-i18next";
import ContactForm from "src/components/contact/ContactForm";
import PageTitle from "src/components/core/PageTitle";

export default function Contact (){
    const { t } = useTranslation(['pages-titles']);
    return (
        <>
            <PageTitle>{t('contact')}</PageTitle>
            <ContactForm />
        </>
    )
}