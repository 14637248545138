import { Box } from "@mui/material";
import NavigationBar from "../core/NavigationBar";
import HomeText from "./HomeText";
import TopBar from "../shared/TopBar";


export default function HomeHeader() {
    return (
        <Box className="home-header" sx={{backgroundImage: "url(img/main-rev-img-1.jpg)", backgroundSize: "cover", backgroundPosition: "center", height: "600px", display: "flex", flexDirection: "column", color: "#fff"}}>
            <TopBar />
            <NavigationBar />
            <HomeText />
        </Box>
    )
}