import { AccessTime, Info, LocalShipping, ShoppingBag } from "@mui/icons-material";
import { Box, Chip, Paper, Typography, useColorScheme } from "@mui/material";

export default function TabElement({ label, data, price, duration = null, details = null, delivery = null }) {
  const colorMode = useColorScheme();

  return (
    <Paper elevation={0} sx={{ width: { xs: "100%", md: "calc(50% - 5px)", lg: "calc((100% / 3) - (40px / 3))" }, flexGrow: "1", display: "flex", flexDirection: "column", boxSizing: "border-box", p: "20px", gap: "10px", border: "1px solid", borderColor: colorMode.mode === "dark" ? "primary.dark" : "grey.300", boxShadow: 'rgba(0,0,0,0.05) 0px 3px 5px 1px', borderRadius: "10px" }}>
      <Typography variant="h5">{label}</Typography>
      <Typography variant="body1" sx={{ flexGrow: "1" }}>{data}</Typography>
      <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
        <Chip icon={<ShoppingBag />} label={price} color="primary" />
        {duration !== null && <Chip icon={<AccessTime />} label={duration} color="primary" />}
        {details !== null && <Chip icon={<Info />} label={details} color="secondary" />}
        {delivery !== null && <Chip icon={<LocalShipping />} label={delivery} color="secondary" />}
      </Box>
    </Paper>
  );
};