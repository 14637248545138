import { Outlet } from "react-router-dom";
import Footer from "../components/core/Footer";
import HomeHeader from "src/components/home/Header";

export default function HomeLayout () {
    return (
        <div>
            <HomeHeader />
            <Outlet />
            <Footer />
        </div>
    )
}