import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

export default function CookiesPolicyDialog() {
  const { t } = useTranslation(['cookies']);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem('cookies');
    if (!isCookieAccepted) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('cookies', 'true');
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("title")}</DialogTitle>
      <DialogContent>
        {t("message")}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          {t("button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}