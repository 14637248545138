import './Home.css';
import React from 'react';
import TextComponent from 'src/components/home/TextComponent';
import ImageText from 'src/components/home/ImageText';
import { useTranslation } from 'react-i18next';
import ImageGrid from 'src/components/home/ImageGrid';
import WeatherData from 'src/components/home/WeatherData';
import PageTitle from 'src/components/core/PageTitle';

export default function Home() {
    const { t } = useTranslation(['home', 'pages-titles']);
    const imageUrls = [
        'img/h1-gallery-01.jpg',
        'img/h1-gallery-02.jpg',
        'img/h1-gallery-03.jpg',
        'img/h1-gallery-04.jpg',
        'img/h1-gallery-05.jpg',
        'img/h1-gallery-06.jpg',
    ];
    return (
        <>
            <PageTitle>{t("home", { ns: 'pages-titles' })}</PageTitle>
            <TextComponent
                title={t("vinicultural_legacy.title")}
                subtitle={t("vinicultural_legacy.subtitle")}
                paragraph1={t("vinicultural_legacy.paragraphs.0")}
                paragraph2={t("vinicultural_legacy.paragraphs.1")}
            />
            <ImageText
                title={t("image-text-1.title")}
                image="img/landing-parallax-img.jpg"
            />
            <TextComponent
                title={t("innovation_sustainability.title")}
                subtitle={t("innovation_sustainability.subtitle")}
                paragraph1={t("innovation_sustainability.paragraphs.0")}
                paragraph2={t("innovation_sustainability.paragraphs.1")}
            />
            <ImageGrid imageUrls={imageUrls} />
            <WeatherData />
        </>

    );

}
